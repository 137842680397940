* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.colors {
  /* color:#69c969  green*/
  /* color:#64b2ff   blue*/
  /* color:#eacf32 yellow */
  /* color:#ffb8c6 pink */
  /* color:#ffb05a  orange*/
  /* color:#b5b2ea purple*/
}

.module-1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 1rem;
  width: 33%;
  /* height: 20rem; */
  /* background-color: #69c969; */
}

.modules-title {
  margin: 0rem 2rem;
}

.modules-row-1 {
  margin: 0rem 2rem 2rem 2rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
}

.module-1{

}

.line1 {
  background-color: #69c969;
  height: 5px;
  width: 100%;
  margin: 0rem 0.5rem !important;
}
.line2 {
  background-color: #64b2ff;
  height: 5px;
  width: 100%;
  margin: 0rem 0.5rem !important;
}
.line3 {
  background-color: #eacf32;
  height: 5px;
  width: 100%;
  margin: 0rem 0.5rem !important;
}
.line4 {
  background-color: #ffb8c6;
  height: 5px;
  width: 100%;
  margin: 0rem 0.5rem !important;
}
.line5 {
  background-color: #ffb05a;
  height: 5px;
  width: 100%;
  margin: 0rem 0.5rem !important;
}
.line6 {
  background-color: #b5b2ea;
  height: 5px;
  width: 100%;
  margin: 0rem 0.5rem !important;
}

.num-Line {
  display: flex;
  flex-direction: row;
  /* background-color: red; */
  width: 100%;
  align-items: center;
}
.num span {
  font-size: 2rem;
}

.smalltext{
padding: 0.7rem 0rem;
}
.view-module-btn{
    margin-top: 0.7rem;
}

.view-module-btn span {
    color: #64b2ff;
}
.module-head a, .view-module-btn a{
text-decoration: none;
}
.module-head a{
color: #28292b;
}
.module-head a:hover{
    color: #64b2ff;
    }


    @media only screen and (max-width:950px){
        .module-1 {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;
            padding: 1rem;
            width: 100%;
            /* height: 20rem; */
            /* background-color: #69c969; */
            flex-direction: column;
          }

          .modules-row-1 {
            margin: 0rem 1rem;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            gap: 20px;
            flex-direction: column;
          }
    }