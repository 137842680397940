* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  


  .navbar-2 {
    background-color: white;
    /* height: 5rem; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0rem 2rem;
  }
  
  ul, h6{
    margin-bottom: 0rem !important;
  }
  .navbar-2 ul {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .navbar-2 ul li {
    list-style: none;
    text-decoration: none;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem;
  }
  .prfileImage{
    padding: 0rem !important;
  }
  .navbar-2 ul li a {
    color: #28292b;
    text-decoration: none !important;
    font-size: 1rem;
    font-weight: 600;
  }

  .navbar-2 ul li span {
    color: #28292b;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
  }

  .signupLogin{
text-decoration: none !important;
color: #000 !important;
  }

  .LogoTitle{
    text-decoration: none !important;
    /* color: #000 !important; */
  }






  .hamburger {
    float: right;
    right: 2rem;
    position: absolute;
    display: none;
  }
  
.signupLogin{
  /* padding: 0.7rem; */
  /* background: #ff8773; */
  border-radius: 10px;
  text-decoration: none;
  color: black;
  font-weight: 600;
  cursor: pointer;
}


  @media screen and (max-width: 950px) {
    .nav-menu {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      height: 100vh;
      top: 0;
      left: -100%;
      position: absolute;
      color: #ffffff;
      /* color: #; */
      background-color: #003049;
      z-index: 33;
      transition: 0.3s;
      /* padding-top: 0.5rem !important; */
    }
    .navbar-2 ul li {
      padding-top: 3rem;
    }
    .navbar-2 ul li a {
      font-size: 1.8rem;
      color: #fff;
    }
    .hamburger {
      display: block;
      z-index: 33;
      right: 1rem;
    }
    .navbar-2{
        padding: 1rem 0.5rem;
    }
  }
  
  .nav-menu.active {
    left: 0;
  }
  

  a{
    text-decoration: none;
  }