*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.line134{
    background-color: #FFB8C6 !important;
    height: 5px !important;
    width: 20rem !important;
    margin: 0rem 0.5rem !important;
}

.introstocks{
    margin: 5rem;
    line-height: 1.5;
    color: #28292b;
}
.introstocks p{
padding: 0.5rem 0rem;
}

  .num-Line {
    display: flex;
    flex-direction: row;
    /* background-color: red; */
    width: 100%;
    align-items: center;
  }
  .num span {
    font-size: 3rem;
    font-weight: 700;
  }

  .linksOfItS{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    padding:1rem 1rem;
    /* background-color: #64b2ff; */
    background-color: #f8e4e4 !important; 
    width: 30% !important;
    border-radius: 10px;
  }
  .linksOfItS a{
    text-decoration: none;
    color: #64b2ff;
    font-weight: 400;
  }

  .linksandpara{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 1rem 0rem 1rem 0rem;
  }
  .sideContainer{
    width: 70%;
    padding: 1rem;
  }


@media only screen and (max-width:950px){
    .introstocks{
      margin: 1rem !important;
      line-height: 1.5;
      color: #28292b;
  }
  .linksandpara{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column-reverse !important;
    padding: 1rem 0rem 1rem 0rem;
  }
  .sideContainer{
    width: 100% !important;
    padding: 1rem;
  }
  
  .linksOfItS{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column !important;
    gap: 20px;
    padding:1rem 1rem;
    /* background-color: #64b2ff; */
    background-color: #f8e4e4 !important; 
    min-width: 100% !important;
    border-radius: 10px !important;
  }
  }