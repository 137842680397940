/* chat.css */

/* Chat window container */
.chat-container {
    width: 300px;
    height: 400px;
    border: 1px solid #ccc;
    overflow-y: auto; /* Enable vertical scrollbar if content exceeds height */
    padding: 10px;
    display: flex;
    flex-direction: column;
 
  }
  

  
  /* Chat message */
  .message {
    background-color: #f2f2f2;
    padding: 5px 10px;
    margin-bottom: 10px;
    border-radius: 5px;
  }
  
  /* Input area */
  .input-area {
    margin-top: 10px;
    align-self: flex-end; 
  }
  
  /* Input field */
  .input-area input {
    width: calc(100% - 60px);
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 20px;
    margin-right: 10px;
  }
  
  /* Send button */
  .input-area button {
    padding: 8px 15px;
    border: none;
    background-color: #007bff;
    color: #fff;
    border-radius: 20px;
    cursor: pointer;
  }



  /* chatwindow.css */


.message {
  margin: 5px;
  padding: 10px;
  border-radius: 5px;
}

.sent {
  background-color: #007bff;
  color: white;
  align-self: flex-end;
}

.received {
  background-color: #f0f0f0;
  color: #333;
  align-self: flex-start;
}

.input-area {
  margin-top: 20px;
  align-self: flex-end; 
}

.input-area input[type="text"],
.input-area input[type="file"] {
  margin-right: 10px;
}

.input-area button {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.input-area button:hover {
  background-color: #0056b3;
}

  