/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */





.saveImagebtn{
  width: auto;
  height: auto;
  background-color: green;
  color: white;
  border-radius: 10px;
  border:none;
  padding: 15px 40px;
}


.Chat__wrapper {
  
  /* background: white;box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22); */

  height: 370px;width: 100%;overflow-y: scroll;overflow-x: auto;

}
.Chat {list-style: none;margin: 0;padding: 20px;}
.Chat_item {display: flex;align-items: flex-end;margin-top: 8px;}
.i_man {border-radius: 50%;overflow: hidden;flex-shrink: 0;flex-grow: 0;height: 32px;width: 32px;}
.i_man-image {display: block;width: 100%;}
.Chat_msgs {display: flex;flex-direction: column;}
.msg {color: white;letter-spacing: 0.5px;line-height: 1.25;font-weight: 300;margin: 1px;}
.msg-content {background: #333;display: inline-block;max-width: 260px;padding: 8px 16px;}
.Chat_item_l .Chat_msgs {margin-left: 15px;}
.Chat_item_l .msg-content { border-radius: 0 15px 15px 0;}
.Chat_item_l .msg:first-child .msg-content {border-top-left-radius: 15px;}
.Chat_item_l .msg:last-child .msg-content {border-bottom-left-radius: 15px;}
.Chat_item_r {  justify-content: flex-end;}
.Chat_item_r .Chat_msgs { align-items: flex-end;}
.Chat_item_r .msg-content { background-attachment: fixed;background-image: linear-gradient(#0030e0, #fb2a09);background-size: 100vw 100vh;border-radius: 15px 0 0 15px;}
.Chat_item_r .msg:first-child  .msg-content {border-top-right-radius: 15px;}
.Chat_item_r .msg:last-child .msg-content {border-bottom-right-radius: 15px;}
.Chat__wrapper::-webkit-scrollbar-track{-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);background-color: #111111;}
.Chat__wrapper::-webkit-scrollbar{width: 10px;}
.Chat__wrapper::-webkit-scrollbar-thumb{border-radius: 10px;-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);background-color: #333;}
































.answers{
  background-color: #739FCC;
}