*{
margin: 0;
padding: 0;
box-sizing: border-box;
}

.topupH2{
font-size: 2.5rem;
font-weight: 700;
/* color: #fff; */
}

.topUp-main-container{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90vh;
    flex-direction: column;
    /* background-color:#f7ede2; */
    background-color: #dad7cd;
    background-image: url('../assets/topup/candle3.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size:cover;
    overflow-y: hidden;
    overflow-x: hidden;
    margin: 0rem;
    padding: 3rem;
    /* opacity: 0.5; */
}


.topupcard{
/* background-color: #f6bd60; */
/* background-color: #e9c46a; */
background-color: #fff; 
padding: 20px;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
gap: 1rem;
border-radius: 10px;
box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(10px);
background:rgba(0, 0, 0, 0.47);

}
#amountInput{
    border-radius: 5px;
    padding: 0.5rem;
    width: 100% !important;
    border: 1px solid #ccc;    
}

.lableInput{
    width: 100% !important;
}

.topUpBtn{
    background-color: #cdb4db;
    /* background-color: #84a59d; */
    color: #000;
    font-weight: 600;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100%;
}


@media only screen and (max-width:950px){
    .topUp-main-container{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 90vh;
        flex-direction: column;
        /* background-color:#f7ede2; */
        background-color: #dad7cd;
        background-image: url('../assets//topup/candle3.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size:cover;
 
        /* opacity: 0.5; */
    }   
}