*{
    margin:0;
    padding: 0;
    box-sizing: border-box;
}


.introstocks{
    margin: 5rem 15rem !important;
    line-height: 1.5;
    color: #28292b;
}
.introstocks p{
padding: 0.5rem 0rem;
}
.line1 {
    background-color: #69c969;
    height: 5px;
    width: 20rem;
    margin: 0rem 0.5rem !important;
  }

  .heading h1 {
font-size: 2rem;
  }

  .num-Line {
    display: flex;
    flex-direction: row;
    /* background-color: red; */
    width: 100%;
    align-items: center;
  }
  .num span {
    font-size: 3rem;
    font-weight: 700;
  }

  .linksOfItS{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    padding:1rem 1rem;
    /* background-color: #64b2ff; */
    /* background-color: #90a955 !important;  */
    background-color: #f8e4e4 !important; ;
    width: 30% !important;
    border-radius: 10px;
  }
  .linksOfItS h4{
    /* text-decoration:underline !important; */
    text-underline-offset: 0.5rem;
    text-decoration-color: #ffafcc !important;
   
  
  }
  .linksOfItS a{
    text-decoration: none;
    /* color: #64b2ff; */
    color: #28292b !important;
    font-weight: 500 !important;
  }

  .linksOfItS a:hover{
    color: #64b2ff !important;
  }

  .linksandpara{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 1rem 0rem 1rem 0rem;
  }
  .sideContainer{
    width: 70%;
    padding: 1rem;
  }


@media only screen and (max-width:950px){
    .introstocks{
      margin: 1rem !important;
      line-height: 1.5;
      color: #28292b;
  }
  .linksandpara{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column-reverse !important;
    padding: 1rem 0rem 1rem 0rem;
  }
  .sideContainer{
    width: 100% !important;
    padding: 1rem;
  }
  
  .linksOfItS{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column !important;
    gap: 20px;
    padding:1rem 1rem;
    /* background-color: #64b2ff; */
    background-color: #f8e4e4 !important; 
    min-width: 100% !important;
    border-radius: 10px !important;
  }
  }