.stockSuggestionlist-ul{
	position: absolute;
	background: white;
	z-index: 8;
	box-shadow: 0px 2px 10px 1px  grey;
	border-radius: 0px 0px 5px 5px;
	width: 100%;

}

.stockSuggestionlist-li{
	list-style-type: none;
	padding: 10px;
	border-bottom: 1px solid #f1f1f1;
}


/* trading.css */

.button-container {
    display: flex;
  }
  
  .button {
    padding: 10px 20px;
    margin: 5px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
  }
  
  .buy-button {
    background-color: #4CAF50; /* Green color for Buy button */
    color: white;
    border: none;
  }
  
  .sell-button {
    background-color: #FF5733; /* Orange color for Sell button */
    color: white;
    border: none;
  }

  .stock-button{

	padding: 10px 20px;
    margin: 5px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
  }
  
  .stock-button-holder{
	display: flex;
	justify-content: center; /* Horizontally center the buttons */
	align-items: center; 
  }


  .dialog {
	
	position: absolute;
	z-index: 8;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgba(0,0,0,0.4);
  }
  
  .dialog-content {
	background-color: #fefefe;
	margin: 1% auto;
	padding: 20px;
	bottom: 1px;
	right: 0;
	left: 0;
	position: absolute;
	border: 1px solid #888;
	width: 40%;
  }
  
  .close {
	color: #aaa;
	float: right;
	font-size: 28px;
	font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
	color: black;
	text-decoration: none;
	cursor: pointer;
  }
  

  .place-button{
	background-color: #006bf7; 
	color: white;
	font-size: 12pt;
	font-weight: bold;
	border-radius: 10px;
	padding: 5px;
	width: 20%;
	text-align: center;

	/* Green color for Buy button */
  }


  .completed{
	background-color: #4CAF50;
	text-align: center;
	color: black;
	font-weight: bold;
	padding: 0.5rem;
	border-radius: 10px;
  }
  .pending{
	background-color: #FF5733;
	text-align: center;
	color: white;
	font-weight: bold;
	padding: 0.5rem;
	border-radius: 10px;
  }