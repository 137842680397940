/* styles.css */

.button-learn {
    background-color: orange;
    color: black;
    padding: 10px 20px;
    border: none;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
  }
  