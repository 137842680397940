/* Login.css

.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .login-form {
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 300px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  input[type="text"],
  input[type="password"] {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .login-btn {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .login-btn:hover {
    background-color: #0056b3;
  }
   */


   /* Login.css */

   .login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 50%;
    flex-direction: column;
    gap: 1rem;
    /* background-image:url(../../assets/chart.jpg); */
    /* background-repeat: no-repeat; */
    /* background-size: cover; */
    /* background-color: #007bff; */
    background-color: #bde0fe;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
  
  }
  
  .login-container  h2 {
    color: #fff;
  }
  
  .login-form {
    /* background-color: #f5f5f5;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 350px; */
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    font-weight: 500;
  }
  
  input[type="text"],
  input[type="password"]
  input[type="date"] {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  
#birthDate{
  width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding-left: 2rem;
    padding-right: 2rem;
}

  .login-btn {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 0.7rem;
  }
  
  .login-btn:hover {
    background-color: #0056b3;
  }
  
  .signupLink a{
    color: #0056b3;
    text-decoration: none;
    font-weight: 600;
  }
  
  .input-with-icon {
    position: relative;
  }
  
  .icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #495057;
  }
  
  .password-toggle {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .eye-icon {
    cursor: pointer;
    /* color: #6c757d; */
    color: #495057;
  }
  
  .loginMain-Container{
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: grey; */
  height: 100vh;
  }
  
  .brand{
    color: #007bff;
  }
  
  .animatedGraph{
    width: 60%;
    gap: 1rem;
    display: flex;
    flex-direction: column;
    padding: 5rem;
    /* background-color: ; */
  }
  
  .animatedGraph h2{
  font-size: 3rem;
  color: #28292b;
  }
  
  .animatedGraph h5{
    font-size: 1.5rem;
    color: #28292b;
    }
  
   .login-form h2{
  color:#28292b;
  margin-bottom: 1rem;
   } 
  
   .login-form p{
    color:#28292b;
    margin-bottom: 1rem;
     } 
  
    .forgetPass a{
      text-decoration: none;
      color: #28292b;
    }

    .firstLastnameCon{
        display: flex;
        gap: 1rem;
    }

    .passDate{
        display: flex;
        gap: 1rem;
    }

   


    @media only screen and (max-width:950px){
      .loginMain-Container{
        display: flex;
        align-items: center;
        justify-content: space-between !important;
        flex-direction: column;
        width: 100%;
        /* background-color: grey; */
        height: auto !important;
        }
        .animatedGraph{
          width: 100% !important;
          gap: 1rem;
          display: flex;
          flex-direction: column;
          padding: 1rem !important;
          /* background-color: ; */
        }
        .login-container {
          display: flex;
          justify-content: center;
          align-items: center;
          height: auto !important;
          width: 100% !important;
          flex-direction: column;
          gap: 1rem;
          padding: 2rem 1rem!important;
          /* background-image:url(../../assets/chart.jpg); */
          /* background-repeat: no-repeat; */
          /* background-size: cover; */
          /* background-color: #007bff; */
          background-color: #bde0fe;
          /* border-top-left-radius: 50px; */
          /* border-bottom-left-radius: 50px; */
          border-radius: 10px !important;
          margin: 0rem 1rem !important;

        }
        .login-form{
          width: 100%;
        }
        .firstLastnameCon,.passDate{
          display: flex;
          flex-direction: column;
          gap: 0rem;
        }
    }